<template>
  <b-col md="6" class="my-1">
    <b-form-group
      label-for="link"
      label-cols-sm="4"
      label-cols-lg="3"
      label-align-sm="right"
      label-size="sm"
      :label="text"
    >
      <b-button
        v-if="link != '' && link != null"
        variant="primary"
        class="mr-2"
        @click="jump"
        :disabled="link == '' || link == null"
        >Open Link</b-button
      >
      <p class="mb-0" v-else>
        N/A
      </p>
      <b-input-group v-if="0">
        <template v-slot:append>
          <b-input-group-text :class="'link'" @click="jump">
            Open Link
          </b-input-group-text>
        </template>
        <b-form-input
          v-model="link"
          :type="'text'"
          :placeholder="this.$t('page.please_choose_input')"
          id="link"
          disabled
        >
        </b-form-input>
      </b-input-group>
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  name: 'ReferenceLink',
  components: {},
  data() {
    return {};
  },
  props: {
    link: {
      type: String
    },
    text: {
      type: String
    }
  },
  methods: {
    jump() {
      window.open(this.link);
    }
  },
  computed: {},
  mounted() {}
};
</script>
<style scoped>
.input-group-text.link {
  background-color: #00a0e9;
  cursor: pointer;
  color: #fff;
  border-color: #00a0e9;
}
p {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}
</style>
