<template>
  <div>
    <b-row v-if="initData.product_info != null">
      <b-col md="12">
        <b-nav tabs class="form-section">
          <b-nav-item disabled>Article Information</b-nav-item>
        </b-nav>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col class="my-1">
            <b-form-group
              label-for="sku"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Brand"
            >
              <b-form-input
                v-model="initData.product_info.brand"
                type="text"
                placeholder=""
                id="sku"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="my-1">
            <b-form-group
              label-for="brand_article_number"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Brand Article Number"
            >
              <b-form-input
                v-model="initData.product_info.brand_article_number"
                type="text"
                placeholder=""
                id="brand_article_number"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="my-1">
            <b-form-group
              label-for="article_name"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Article Name CN"
            >
              <b-form-input
                v-model="initData.product_info.product_name"
                type="text"
                placeholder=""
                id="article_name"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="my-1">
            <b-form-group
              label-for="product_name_en"
              label-cols-sm="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              label="Article Name EN"
            >
              <b-form-input
                v-model="initData.product_info.product_name_en"
                type="text"
                placeholder=""
                id="product_name_en"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="6">
        <div class="my-1">
          <b-form-group
            label-for="image"
            label-cols-sm="4"
            label-cols-lg="3"
            label-align-sm="right"
            label-size="sm"
            label=""
            class="image-box"
          >
            <div>
              <img
                @click="showImg()"
                v-if="
                  initData.product_info.image_url != null &&
                    initData.product_info.image_url != ''
                "
                class="image pointer"
                :src="initData.product_info.image_url"
                alt="image"
              />
              <img
                v-else
                alt="edit"
                class="image pointer"
                src="@/assets/media/users/default.png"
              />
            </div>
          </b-form-group>
        </div>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-for="ean_number"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="EAN Number"
        >
          <b-form-input
            v-model="initData.product_info.ean_number"
            type="text"
            placeholder=""
            id="ean_number"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-for="color"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Color"
        >
          <b-input-group>
            <template v-slot:append>
              <b-input-group-text v-if="initData.product_info.color">
                <div
                  v-if="initData.product_info.color.indexOf('#') >= 0"
                  class="color-box"
                  v-b-popover.hover.topleft="initData.product_info.org_color"
                  :style="'background:' + initData.product_info.color"
                ></div>
                <div
                  v-else
                  class="color-box"
                  :style="
                    `background:url(${initData.product_info.color});background-size: 100%;`
                  "
                ></div>
              </b-input-group-text>
            </template>
            <b-form-input
              v-model="initData.product_info.color_name"
              type="text"
              placeholder=""
              id="color"
              :disabled="
                initData.status != 'New' && initData.status != 'Processing'
              "
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>

      <ReferenceLink
        v-if="initData.product_info.reference_link != null"
        :link="initData.product_info.reference_link"
        :text="'Reference Link'"
      ></ReferenceLink>
      <b-col md="6" class="my-1">
        <b-form-group
          label-for="size"
          label-cols-sm="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          label="Size"
        >
          <b-form-input
            v-model="initData.product_info.size"
            type="text"
            placeholder=""
            id="size"
            :disabled="
              initData.status != 'New' && initData.status != 'Processing'
            "
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-modal
      v-model="imageModal"
      centered
      no-close-on-backdrop
      hide-footer
      scrollable
      title="Image"
      id="image-box"
      ref="modal"
      size="lg"
    >
      <!-- <div class="showImg"> -->
      <img
        v-if="initData.product_info != null"
        :src="initData.product_info.image_url"
        alt=""
      />

      <!-- </div> -->
      <!-- <template v-slot:modal-footer="{ close }">
        <b-button size="sm" variant="secondary" @click="close()">{{
          $t('page.close')
        }}</b-button>
      </template> -->
    </b-modal>
  </div>

  <!-- <Others></Others> -->
</template>

<script>
import ReferenceLink from '@/components/ReferenceLink/Index.vue';
// import SelectLoading from '@/components/Loading/Index';
// import MerchantPartner from '@/views/content/Detail/Information/MerchantPartner';
// import Others from '@/views/content/Detail/Information/Others';
export default {
  name: 'ArticleInformation',

  data() {
    return {
      imageModal: false
    };
  },
  props: {
    initData: Object,
    options: Object
  },
  inheritAttrs: false,
  components: {
    ReferenceLink
    // SelectLoading
    // MerchantPartner
    // Others
  },
  methods: {
    showImg() {
      this.imageModal = true;
    }
  },

  computed: {},
  mounted() {}
};
</script>
<style scoped>
.image-box .bv-no-focus-ring {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  max-width: 100%;
  max-height: 170px;
}
</style>
