<template>
  <div>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Target ID"
          label-align-sm="right"
          label-size="sm"
          label-for="target_id"
        >
          <b-form-input
            id="target_id"
            v-model="initData.system_id"
            name="target_id"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Status"
          label-align-sm="right"
          label-size="sm"
          label-for="status"
        >
          <div
            v-if="initData.status != null"
            :class="['color_' + initData.status.replace(/ /g, '_')]"
            class="text status"
          >
            {{ initData.status }}
          </div>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Order ID"
          label-align-sm="right"
          label-size="sm"
          label-for="order_id"
        >
          <b-input-group>
            <template v-slot:append>
              <b-input-group-text
                class="pointer"
                @click="blankUrl(initData.cps_order_url)"
              >
                <b-icon icon="search"></b-icon>
              </b-input-group-text>
            </template>

            <b-form-input
              id="order_id"
              v-model="initData.cps_order_number"
              name="order_id"
              disabled
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Order Type"
          label-align-sm="right"
          label-size="sm"
          label-for="order_type"
        >
          <!--  { label: 'Fulfillment Only', value: 4 } -->
          <sv-select
            v-model="initData.order_type"
            disabled
            :placeholder="$t('page.please_choose')"
            :options="[
              { label: 'Retail', value: 1 },
              { label: 'Wholesale', value: 2 },
              { label: 'Deposit', value: 3 },
              { label: 'Fulfillment Only', value: 4 }
            ]"
            :clearable="false"
            :reduce="options => options.value"
            :selectable="options => !options.disabled"
            label="label"
          >
          </sv-select
        ></b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Order Date"
          label-align-sm="right"
          label-size="sm"
          label-for="order_date"
        >
          <b-form-input
            id="order_date"
            v-model="initData.order_date"
            name="order_date"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Priority"
          label-align-sm="right"
          label-size="sm"
          label-for="order_priority"
        >
          <sv-select
            v-model="initData.order_priority"
            :disabled="
              initData.status != 'New' && initData.status != 'Processing'
            "
            :placeholder="$t('page.please_choose')"
            :options="[
              { label: 'VIP', value: 1, class: 'text_red' },
              { label: 'Normal', value: 2 },
              { label: 'Urgent', value: 3, class: 'text_yellow_dashed' }
            ]"
            :clearable="false"
            :reduce="options => options.value"
            :selectable="options => !options.disabled"
            label="label"
          >
          </sv-select>
        </b-form-group>
      </b-col>

      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="Supplier Confirmation Deadline"
          label-align-sm="right"
          label-size="sm"
          label-for="supplier_confirmation_deadline"
        >
          <b-form-input
            id="supplier_confirmation_deadline"
            v-model="initData.supplier_confirmation_deadline"
            name="supplier_confirmation_deadline"
            disabled
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          label-cols-sm="4"
          label-cols-lg="3"
          label="To Warehouse Deadline"
          label-align-sm="right"
          label-size="sm"
          label-for="carbin_deadline"
          class="required"
          :class="{
            form_error: submitError && initData.to_warehouse_deadline == null
          }"
        >
          <b-input-group>
            <template v-slot:append>
              <div class="datepicker-x" @click="initialTime()" v-if="0">
                <b-icon-x font-scale="1.45"></b-icon-x>
              </div>
              <b-input-group-text>
                <b-icon-calendar2-date></b-icon-calendar2-date>
              </b-input-group-text>
            </template>
            <b-form-datepicker
              v-model="initData.to_warehouse_deadline"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              }"
              :hide-header="true"
              placeholder="No date selected"
              locale="de"
            ></b-form-datepicker>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <ArticleInformation :initData="initData"></ArticleInformation>
    <PurchaseOverview
      :initData="initData"
      :submitError="submitError"
    ></PurchaseOverview>
  </div>

  <!-- <Others></Others> -->
</template>

<script>
// import SelectLoading from '@/components/Loading/Index';
// import MerchantPartner from '@/views/content/Detail/Information/MerchantPartner';
// import Others from '@/views/content/Detail/Information/Others';
// import apiBus from '@/common/apiBus/index';
import ArticleInformation from '@/views/content/Detail/PurchaseTarget/Information/ArticleInformation.vue';
import PurchaseOverview from '@/views/content/Detail/PurchaseTarget/Information/PurchaseOverview';

export default {
  name: 'Information',

  data() {
    return {
      id: null
    };
  },
  props: {
    initData: Object,
    options: Object,
    submitError: Boolean
  },
  inheritAttrs: false,
  components: {
    ArticleInformation,
    PurchaseOverview
    // SelectLoading
    // MerchantPartner
    // Others
  },
  methods: {
    blankUrl(url) {
      window.open(url);
    },
    initialTime() {
      this.initData.to_warehouse_deadline = null;
    },
    init() {
      // this.initData();
    }
  },

  computed: {},
  mounted() {
    this.init();
  }
};
</script>
<style scoped></style>
