var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"my-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"3","label":"Target ID","label-align-sm":"right","label-size":"sm","label-for":"target_id"}},[_c('b-form-input',{attrs:{"id":"target_id","name":"target_id","disabled":""},model:{value:(_vm.initData.system_id),callback:function ($$v) {_vm.$set(_vm.initData, "system_id", $$v)},expression:"initData.system_id"}})],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"3","label":"Status","label-align-sm":"right","label-size":"sm","label-for":"status"}},[(_vm.initData.status != null)?_c('div',{staticClass:"text status",class:['color_' + _vm.initData.status.replace(/ /g, '_')]},[_vm._v(" "+_vm._s(_vm.initData.status)+" ")]):_vm._e()])],1),_c('b-col',{staticClass:"my-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"3","label":"Order ID","label-align-sm":"right","label-size":"sm","label-for":"order_id"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"pointer",on:{"click":function($event){return _vm.blankUrl(_vm.initData.cps_order_url)}}},[_c('b-icon',{attrs:{"icon":"search"}})],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"order_id","name":"order_id","disabled":""},model:{value:(_vm.initData.cps_order_number),callback:function ($$v) {_vm.$set(_vm.initData, "cps_order_number", $$v)},expression:"initData.cps_order_number"}})],1)],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"3","label":"Order Type","label-align-sm":"right","label-size":"sm","label-for":"order_type"}},[_c('sv-select',{attrs:{"disabled":"","placeholder":_vm.$t('page.please_choose'),"options":[
            { label: 'Retail', value: 1 },
            { label: 'Wholesale', value: 2 },
            { label: 'Deposit', value: 3 },
            { label: 'Fulfillment Only', value: 4 }
          ],"clearable":false,"reduce":function (options) { return options.value; },"selectable":function (options) { return !options.disabled; },"label":"label"},model:{value:(_vm.initData.order_type),callback:function ($$v) {_vm.$set(_vm.initData, "order_type", $$v)},expression:"initData.order_type"}})],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"3","label":"Order Date","label-align-sm":"right","label-size":"sm","label-for":"order_date"}},[_c('b-form-input',{attrs:{"id":"order_date","name":"order_date","disabled":""},model:{value:(_vm.initData.order_date),callback:function ($$v) {_vm.$set(_vm.initData, "order_date", $$v)},expression:"initData.order_date"}})],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"3","label":"Priority","label-align-sm":"right","label-size":"sm","label-for":"order_priority"}},[_c('sv-select',{attrs:{"disabled":_vm.initData.status != 'New' && _vm.initData.status != 'Processing',"placeholder":_vm.$t('page.please_choose'),"options":[
            { label: 'VIP', value: 1, class: 'text_red' },
            { label: 'Normal', value: 2 },
            { label: 'Urgent', value: 3, class: 'text_yellow_dashed' }
          ],"clearable":false,"reduce":function (options) { return options.value; },"selectable":function (options) { return !options.disabled; },"label":"label"},model:{value:(_vm.initData.order_priority),callback:function ($$v) {_vm.$set(_vm.initData, "order_priority", $$v)},expression:"initData.order_priority"}})],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols-sm":"4","label-cols-lg":"3","label":"Supplier Confirmation Deadline","label-align-sm":"right","label-size":"sm","label-for":"supplier_confirmation_deadline"}},[_c('b-form-input',{attrs:{"id":"supplier_confirmation_deadline","name":"supplier_confirmation_deadline","disabled":""},model:{value:(_vm.initData.supplier_confirmation_deadline),callback:function ($$v) {_vm.$set(_vm.initData, "supplier_confirmation_deadline", $$v)},expression:"initData.supplier_confirmation_deadline"}})],1)],1),_c('b-col',{staticClass:"my-1",attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"required",class:{
          form_error: _vm.submitError && _vm.initData.to_warehouse_deadline == null
        },attrs:{"label-cols-sm":"4","label-cols-lg":"3","label":"To Warehouse Deadline","label-align-sm":"right","label-size":"sm","label-for":"carbin_deadline"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [(0)?_c('div',{staticClass:"datepicker-x",on:{"click":function($event){return _vm.initialTime()}}},[_c('b-icon-x',{attrs:{"font-scale":"1.45"}})],1):_vm._e(),_c('b-input-group-text',[_c('b-icon-calendar2-date')],1)]},proxy:true}])},[_c('b-form-datepicker',{attrs:{"date-format-options":{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric'
            },"hide-header":true,"placeholder":"No date selected","locale":"de"},model:{value:(_vm.initData.to_warehouse_deadline),callback:function ($$v) {_vm.$set(_vm.initData, "to_warehouse_deadline", $$v)},expression:"initData.to_warehouse_deadline"}})],1)],1)],1)],1),_c('ArticleInformation',{attrs:{"initData":_vm.initData}}),_c('PurchaseOverview',{attrs:{"initData":_vm.initData,"submitError":_vm.submitError}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }